/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component() {
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="home">
    <!-- Top Navbar -->
<!--     <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="title">Sand 🏝</div>
      </div>
    </div> -->
    <!-- Toolbar--><!-- 
    <div class="toolbar toolbar-bottom">
      <div class="toolbar-inner">
        <a href="#" class="link">Left Link</a>
        <a href="#" class="link">Right Link</a>
      </div>
    </div> -->
    <!-- Scrollable page content-->
    <div class="page-content">

      <!-- Start App -->

      <h1>Sand 🏝</h1>
      <!-- Button -->
      <div class="sand-button">🎲</div>

      <!-- dropdown -->
      <br /> <br />
      <h3>How much time do you have?</h3>
      <div class="list">
        <ul>
          <!-- 1 minute -->
          <li>
            <label class="item-radio item-content">
              <!-- Checked by default -->
              <input type="radio" name="my-radio" value="radio-2"/>
              <i class="icon icon-radio"></i>
              <div class="item-inner">
                <div class="item-title">1 Minute</div>
              </div>
            </label>
          </li>
          <!-- 3 minute -->
          <li>
            <label class="item-radio item-content">
              <input type="radio" name="my-radio" value="radio-2" checked/>
              <i class="icon icon-radio"></i>
              <div class="item-inner">
                <div class="item-title">3 Minutes</div>
              </div>
            </label>
          </li>
          <!-- 7 minute -->
          <li>
            <label class="item-radio item-content">
              <input type="radio" name="my-radio" value="radio-2"/>
              <i class="icon icon-radio"></i>
              <div class="item-inner">
                <div class="item-title">7 Minutes</div>
              </div>
            </label>
          </li>
        </ul>
      </div> 

      <!-- End App -->





<!--       <div class="block block-strong">
        <p>Here is your blank Framework7 app. Let's see what we have here.</p>
      </div>
      <div class="block-title">Navigation</div>

        <ul>
          <li>
            <a href="#" class="button button-raised button-fill">Button</a>
          </li>
          <li>
            <a href="/form/" class="item-content item-link">
              <div class="item-inner">
                <div class="item-title">Form</div>
              </div>
            </a>
          </li>
        </ul>

      <div class="block-title">Modals</div>
      <div class="block block-strong">
        <div class="row">
          <div class="col-50">
            <a href="#" class="button button-raised button-fill popup-open" data-popup="#my-popup">Popup</a>
          </div>
          <div class="col-50">
            <a href="#" class="button button-raised button-fill login-screen-open" data-login-screen="#my-login-screen">Login Screen</a>
          </div>
        </div>
      </div>

      <div class="block-title">Panels</div>
      <div class="block block-strong">
        <div class="row">
          <div class="col-50">
            <a href="#" class="button button-raised button-fill panel-open" data-panel="left">Left Panel</a>
          </div>
          <div class="col-50">
            <a href="#" class="button button-raised button-fill panel-open" data-panel="right">Right Panel</a>
          </div>
        </div>
      </div>

      <div class="list links-list">
        <ul>
          <li>
            <a href="/dynamic-route/blog/45/post/125/?foo=bar#about">Dynamic (Component) Route</a>
          </li>
          <li>
            <a href="/load-something-that-doesnt-exist/">Default Route (404)</a>
          </li>
          <li>
            <a href="/request-and-load/user/123456/">Request Data & Load</a>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = 'a262a06c93';
export default framework7Component;