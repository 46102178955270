/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $f7 = _ref.$f7,
      $on = _ref.$on,
      $onBeforeMount = _ref.$onBeforeMount,
      $onMounted = _ref.$onMounted,
      $onBeforeUnmount = _ref.$onBeforeUnmount,
      $onUnmounted = _ref.$onUnmounted;
  // Component Data
  var name = 'Jimmy';
  var age = 25;
  var likes = ['Tennis', 'Chess', 'Football']; // Component Methods

  var openAlert = function openAlert() {
    $f7.dialog.alert('Hello World');
  }; // Lifecycle


  $onBeforeMount(function () {
    console.log('onBeforeMount');
  });
  $onMounted(function () {
    console.log('onMounted');
  });
  $onBeforeUnmount(function () {
    console.log('onBeforeUnmount');
  });
  $onUnmounted(function () {
    console.log('onUnmounted');
  }); // Page Events

  $on('pageMounted', function (e, page) {
    console.log('pageMounted', page);
  });
  $on('pageInit', function (e, page) {
    console.log('pageInit', page);
  });
  $on('pageBeforeIn', function (e, page) {
    console.log('pageBeforeIn', page);
  });
  $on('pageAfterIn', function (e, page) {
    console.log('pageAfterIn', page);
  });
  $on('pageBeforeOut', function (e, page) {
    console.log('pageBeforeOut', page);
  });
  $on('pageAfterOut', function (e, page) {
    console.log('pageAfterOut', page);
  });
  $on('pageBeforeRemove', function (e, page) {
    console.log('pageBeforeRemove', page);
  }); // Return render function

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
        <div class="title">Component Page</div>
      </div>
    </div>
    <div class="page-content">
      <div class="block block-strong">
        <p>Component page template is compiled as ES Template. In addition it has lifecycle hooks, events handling, data managment and Virtual DOM data bindings.</p>
        <p>It is useful to use Component page when you need page-specific logic.</p>
      </div>
      <div class="block-title">Events Handling</div>
      <div class="block block-strong">
        <a href="#" class="button button-raised" @click=${openAlert}>Open Alert</a>
      </div>
      <div class="block-title">Page Component Data</div>
      <div class="block block-strong">
        <p>Hello! My name is ${name}. I am ${age} years old.</p>
        <p>I like to play:</p>
        <ul>
          ${likes.map((item) => $h`
          <li>${item}</li>
          `)}
        </ul>
      </div>
      <div class="block-title">Extended Context</div>
      <div class="block block-strong">
        <p>Component page context as Template7 page context is also extended with some additional variables.</p>
        <h4>$f7route</h4>
        <p>Contains properties of the current route:</p>
        <ul style="padding-left:25px">
          <li><b>$f7route.url</b>: ${$f7route.url}</li>
          <li><b>$f7route.path</b>: ${$f7route.path}</li>
          <li><b>$f7route.params</b>: ${JSON.stringify($f7route.params)}</li>
          <li><b>$f7route.hash</b>: ${$f7route.hash}</li>
          <li><b>$f7route.query</b>: ${JSON.stringify($f7route.query)}</li>
        </ul>

        <h4>$theme</h4>
        <p>Currently active theme:</p>
        <ul style="padding-left:25px">
          <li><b>$theme.ios</b>: ${$theme.ios}</li>
          <li><b>$theme.md</b>: ${$theme.md}</li>
          <li><b>$theme.aurora</b>: ${$theme.aurora}</li>
        </ul>
      </div>
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = 'ada05bcec6';
framework7Component.style = "\n  p {\n    margin: 10px 0;\n  }\n";
export default framework7Component;