/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props) {
  var user = props.user;
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
        <div class="left">
          <a href="#" class="back link">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
        <div class="title">${user.firstName} ${user.lastName}</div>
      </div>
    </div>
    <div class="page-content">
      <div class="block block-strong">
        ${user.about}
      </div>
      <div class="list links-list">
        <ul>
          ${user.links.map((link) => $h`
            <li><a class="external" target="_blank" href="${link.url}">${link.title}</a></li>
          `)}
        </ul>
      </div>
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = 'e050c9cb64';
export default framework7Component;